import packageInfo from '../../package.json'

export const environment = {
    version: packageInfo.version,
    /**
     * Builds as production bundle if true
     */
    production: true,
    /**
     * Time to live for the services to be locally persisted on some services
     * (ContentfulService, ConfigurationService)
     */
    ttl: 180000,
    /**
     * For testing production with JS maps
     * (Automatically true if `production` option is true)
     * (Needed for testing in IE)
     */
    buildForProd: true,

    /**
     * Indicates what environment/stage bugs were reported on.
     */
    releaseStage: 'prod',

    /**
     * Authentication server for Rouse Auth0
     */
    auth: {
        domain: 'login.rouseservices.com',
        clientId: 'nNG9E7fEzSveIcHCK4j8F2swIOrd7YF0',
        audience: 'https://api.rouseservices.com',
        useRefreshTokens: true,
        redirectUri: `${window.location.origin}/tools/components-config`,
    },
    /**
     * To target specific configuration on the mock server
     */
    client: null,

    /**
     * Rouse API servers
     */
    data: {
        pyApi: 'http://localhost:8888/', // Local
        apiLocal: null,
        api: '/api/',
        apiEnvironment: null,
        assetsUrl: 'https://catalog-assets.rousesales.com/'
    },
    firebaseEnv: 'prod',
    firebaseConfig: {
        apiKey: "AIzaSyAbtDDkRn6v6x6FpoETPxkbqxVN9E9rAH8",
        authDomain: "rfm-prod-5f68c8.firebaseapp.com",
        databaseURL: "https://rfm-prod-5f68c8.firebaseio.com",
        projectId: "rfm-prod-5f68c8",
        storageBucket: "rfm-prod-5f68c8.appspot.com",
        messagingSenderId: "635201224585",
        appId: "1:635201224585:web:f8cb2b19b82a7eda0a2b58",
        measurementId: "G-2JDXPBX681"
    },
    traceSampleRate: 0.05,
    /**
     * Request behavior settings
     */
    requests: {
        /**
         * Sets the number of requests to bundle into a single request
         * when request bundling is possible
         */
        batchSize: 6,
        /**
         * Turns on benchmarking for fetch service requests by default.
         * Note: Can turn on benchmarking manually by calling window.rasBenchOn()
         */
        defaultBenchmarkMode: false,
    },
    googleAnalytics: {
        trackingId: 'G-9VZWNS4CBZ',
    },
    /**
     * Enables console-level debugging for specific uses
     */
    debugging: {
        // ----- Debugging Settings
        showTimeInLog: false,    // Shows the time in the console log
        showStackInLog: false,  // Appends the last stack trace to warnings and the calling line to for all other methods except 'error

        // ----- Components/Services
        general: false,         // Consoles out generic events that otherwise are silent
        authentication: false,  // Consoles out authentication events
        pageConstructor: false, // Consoles out page construction events
        fetch: false,           // Consoles out fetch errors and warnings
        filters: false,         // Consoles out filter errors and warnings
        landingRouter: false,   // Consoles out routing decisions
        qaStatus: false,        // Consoles out QA status events
        utility: false,         // Consoles out events occurring in the Utility Service
        session: false,         // Session consoling and timer
    }
}
